import { cn } from '@/lib/utils';
import Link, { LinkProps } from 'next/link';
import { memo } from 'react';

function LinkButton({
	children,
	className,
	color = 'dodger-blue',
	disabled = false,
	...props
}: { children: React.ReactNode; color?: 'dodger-blue' | 'white'; disabled?: boolean } & Omit<
	React.AnchorHTMLAttributes<HTMLAnchorElement>,
	keyof LinkProps
> &
	LinkProps) {
	return (
		<Link
			className={cn(
				'flex items-center justify-center gap-[10px] rounded-2xl px-5 py-3.5 text-base font-medium leading-6 transition-colors',
				{
					'bg-dodger-blue-500 text-white hover:bg-dodger-blue-600': color === 'dodger-blue',
					'bg-white text-black-text hover:bg-slate-300': color === 'white',
					'pointer-events-none opacity-50': disabled,
				},
				className ?? '',
			)}
			{...props}
		>
			{children}
		</Link>
	);
}

export default memo(LinkButton);
